import { Input } from "@/Components/ui/input";
import { Icon } from "@iconify/react";


export default function CodeFinderAndService() {
    const services = [
        {
            title: "Free Shipping",
            description: "On all order over $250",
            icon: "clarity:truck-solid",
        },
        {
            title: "Money Guarantee",
            description: "30 day money back",
            icon: "mdi:handshake-outline",
        },
        {
            title: "Payment Secured",
            description: "Secure all your payments",
            icon: "mdi:security-lock-outline",
        },
        {
            title: "Power Support",
            description: "Online Support 24/7",
            icon: "mdi:support",
        },
    ];
    return <div className="w-screen bg-muted-foreground/10 items-center justify-center flex py-8 px-0">
        <div className="max-w-7xl md:px-0 px-4 flex md:flex-row flex-col justify-between flex-flex-nowrap items-center gap-4">
            <div className="flex flex-row gap-2 justify-start items-center bg-primary rounded-full">
                <span className="whitespace-nowrap text-sm px-4 font-semibold text-muted mr-auto">Code Finder</span>
                <div className="flex relative flex-row gap-2 justify-start w-full items-center">
                    <Input placeholder="Enter Code Here" className="w-full uppercase border-primary text-left focus:text-center rounded-full max-w-[300px]" />
                    <Icon icon="mdi:search" className="absolute right-3 text-primary" width={30} height={30} />
                </div>
            </div>
            <div className="flex-1 overflow-hidden mx-auto flex flex-row md:flex-nowrap flex-wrap md:divide-x divide-x-0 md:justify-end justify-center items-center gap-x-4 gap-y-3">
                {services.map((item) => (
                    <div
                        key={item.icon}
                        className="flex px-4 flex-row justify-start items-center gap-3"
                    >
                        <Icon className="text-[2rem]" icon={item.icon} />
                        <div className="flex flex-col gap-1">
                            <span className="font-semibold text-primary text-lg whitespace-nowrap">
                                {item.title}
                            </span>
                            <p className="text-xs text-foreground">
                                {item.description}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
}
